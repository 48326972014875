import React from 'react';
import Rocket from '../assets/rocket-svg';
import Tardis from '../assets/tardis-svg';
import Trees from '../assets/trees-svg';
import ShowCard from '../components/Cards'
import Copyright from '../components/Copyright';
import '../stylesheets/App.scss';
import ui from '../assets/ui-card.svg'
import web from '../assets/web-card.svg'
import mobile from '../assets/mobile-card.svg'
import ai from '../assets/ai-card.svg'
import dapp from '../assets/dapp-card.svg'
import service from '../assets/service-card.svg'
import {
  useScroll,
  useTransform,
} from "framer-motion/dist/framer-motion";


const cards = [
    {
      image: ui,
      alt: 'ui/ux',
      title: 'UI/UX',
      sub: 'Brand-focused to convey your identity, engage users, and enhance overall user experience.'
    },{
      image: web,
      alt: 'web',
      title: 'Web Apps',
      sub: 'Developing branded web apps to offer engaging digital experiences for your target audiences.'
    },{
      image: mobile,
      alt: 'web apps image',
      title: 'Mobile Apps',
      sub: 'Custom branded mobile apps for user-friendly experiences, reinforcing your brands identity.'
    },{
      image: ai,
      alt: 'ai image',
      title: 'AI',
      sub: 'Integrating AI into your brand\'s ecosystem to enhance capabilities, insights, and customer engagement.'
    },{
      image: dapp,
      alt: 'block chain image',
      title: 'Block Chain',
      sub: 'Custom decentralized applications to optimize user trust, while adding blockchain-based functionalities.'
    },{
      image: service,
      alt: 'maintenance image',
      title: 'Maintenance',
      sub: 'We will manage existing web apps to ensure performance, security, and alignment with brand objectives.'
    }
  ];

  function useParallax(value, distance) {
    return useTransform(value, [1, 0], [0,-distance],{ clamp: true });
  }
  

export default function Main() {

  const { scrollYProgress } = useScroll();
  const y = useParallax(scrollYProgress, 100);

  return (
    <div className="home-wrapper container-fluid">
      <div className="row">
        <main role="main" className="col-lg-12 ml-auto mr-auto" style={{ maxWidth: '800px' }}>
          <div className="content mr-auto ml-auto">

            <h1 className="cta">We craft stellar digital solutions</h1>
            <h5 className="cta-sub">At Stellara Consulting, we specialize in revolutionizing your businesses through cutting-edge mobile, web, and UI/UX solutions.</h5>

            <Rocket className="rocket" />

            {/* <motion.div style={{ y }}> */}
              <div className='cards'>
                {cards.map((card) => (
                  <div className='card' sm>
                    <ShowCard {...card} />
                  </div>
                ))}
              </div>
            {/* </motion.div> */}

            <div className='future'>
              <Tardis className="tardis" />
              <h1 className="agd">Teleport your business into the future, leaving the past behind.</h1>
              <h5 className="agd-sub">We keep pace with cutting-edge technologies and innovations to ensure your digital creations are user-friendly, effortlessly managed, and outshine the rest in the time continuum.</h5>
              <ul className="list">
                <li>Javascript</li>
                <li>Kubernetes</li>
                <li>Typescript</li>
                <li>Wordpress</li>
                <li>Angular</li>
                <li>Solidity</li>
                <li>React</li>
                <li>Docker</li>
                <li>Ionic</li>
                <li>CI/CD</li>
                <li>Node</li>
                <li>Figma</li>
                <li>PWAs</li>
                <li>iOS</li>
                <li>AWS</li>
                <li>SEO</li>
              </ul>
            </div>

            {/* <form onSubmit={(event) => {
              event.preventDefault()
              const description = this.imageDescription.value
              this.props.uploadImage(description)
            }} >
              <input type='file' accept=".jpg, .jpeg, .png, .bmp, .gif" onChange={this.props.captureFile} />
                <div className="form-group mr-sm-2">
                  <br></br>
                    <input
                      id="imageDescription"
                      type="text"
                      ref={(input) => { this.imageDescription = input }}
                      className="form-control"
                      placeholder="Image description..."
                      required />
                </div>
              <button type="submit" className="btn btn-primary btn-block btn-lg">Upload!</button>
            </form>
            <p>&nbsp;</p>
            { this.props.images.map((image, key) => {
              return(
                <div className="card mb-4" key={key} >
                  <div className="card-header">
                    <img
                      className='mr-2'
                      width='30'
                      height='30'
                      src={`data:image/png;base64,${new Identicon(image.author, 30).toString()}`}
                    />
                    <small className="text-muted">{image.author}</small>
                  </div>
                  <ul id="imageList" className="list-group list-group-flush">
                    <li className="list-group-item">
                      <p className="text-center"><img src={`https://ipfs.infura.io/ipfs/${image.hash}`} style={{ maxWidth: '420px'}}/></p>
                      <p>{image.description}</p>
                    </li>
                    <li key={key} className="list-group-item py-2">
                      <small className="float-left mt-1 text-muted">
                        TIPS: {window.web3.utils.fromWei(image.tipAmount.toString(), 'Ether')} ETH
                      </small>
                      <button
                        className="btn btn-link btn-sm float-right pt-0"
                        name={image.id}
                        onClick={(event) => {
                          let tipAmount = window.web3.utils.toWei('0.1', 'Ether')
                          console.log(event.target.name, tipAmount)
                          this.props.tipImageOwner(event.target.name, tipAmount)
                        }}
                      >
                        TIP 0.1 ETH
                      </button>
                    </li>
                  </ul>
                </div>
              )
            })} */}
          </div>
        </main>
      </div>

      <div className="copyright-wrapper">
        <Trees className="trees" />
        <Copyright sx={{ mt: 5 }} />
      </div>
    </div>
  );
}
