import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AppBar,
        Button,
        Toolbar,
        Typography,
        IconButton,
        Menu,
        MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/logo.svg'


const Hamburger = ({ className }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={className}>
    <IconButton
      edge="start"
      color="inherit"
      aria-label="menu"
      onClick={handleMenuOpen}
    >
      <MenuIcon />
    </IconButton>
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <Link to="/">Home</Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Link to="/about">About</Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Link to="/contact">Contact</Link>
      </MenuItem>
    </Menu>
    </div>
  )
}

class Navbar extends Component {

  render() {
    return (
      <AppBar position="static">
      <Toolbar>
        <Hamburger className="d-block d-sm-none" />
        <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            <a className="navbar-brand col-sm-3 col-md-2 mr-0" href="http://StellaraConsulting.com">
              <img src={logo} className="logo d-inline-block align-top" alt="Stellara Logo" />
            </a>
          </Typography>
          <nav className="navbar-nav px-3 d-none d-sm-block">
            <Link variant="button" color="text.primary" to="/">
              Home
            </Link>
            <Link variant="button" color="text.primary" to="/about">
              About
            </Link>
            <Link className="m-0" to="/contact">
              <Button className="btn btn-link btn-sm float-right pt-0" name="clogin" variant="outlined">
                Contact
              </Button>
            </Link>
          </nav>

            {/* <small className="text-secondary">
              <small id="account">{this.props.account}</small>
            </small>
            { this.props.account
              ? <img
                className='ml-2'
                width='30'
                height='30'
                src={`data:image/png;base64,${new Identicon(this.props.account, 30).toString()}`}
                onClick={(event) => {
                  console.log(event, "Logout");
                  // console.log(window.ethereum);
                  // window.ethereum.disable();
                }}
              />
              : <Button
                  className="btn btn-link btn-sm float-right pt-0"
                  name={"clogin"}
                  variant="outlined" 
                  sx={{ my: 1, mx: 1.5 }}
                  onClick={(event) => {
                    console.log(event, "Login");
                    console.log(window.ethereum);
                    window.ethereum.enable()
                  }}
                >
                  Login
              </Button>
            } */}
      </Toolbar>
    </AppBar>
    );
  }
}

export default Navbar;