import Stellara from '../abis/stellara.json'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { Component } from 'react';
import Navbar from './Navbar'
// import Web3 from 'web3';

import Main from '../pages/Main'
import About from '../pages/About';
import Contact from '../pages/Contact';

//Declare IPFS
// const ipfsClient = require('ipfs-http-client')
// const ipfs = ipfsClient({ host: 'ipfs.infura.io', port: 5001, protocol: 'https' }) // leaving out the arguments will default to these values

class App extends Component {

  // async initialize() {
    // window.ethereum.on('accountsChanged', async () => {
    //   const accounts = await window.web3.eth.getAccounts()
    //   this.setState({ account: accounts[0] })
    //   this.loadBlockchainData()
    // })

    // window.ethereum.on('chainChanged', function (chainId) {
    //   // Handle the change in the Ethereum network
    //   console.log('chainChanged', chainId);
    // });

    
    // window.ethereum.on('message', function (message) {
    //   // Handle the custom message from the dApp
    //   console.log('message', message);
    // });
    
  // }

  // async componentWillMount() {
  //   // // await this.loadWeb3()
  //   // await this.loadBlockchainData()
  //   // await this.initialize();
  // }

  // async loadWeb3() {
  //   if (window.ethereum) {
  //     window.web3 = new Web3(window.ethereum)
  //   }
  //   else if (window.web3) {
  //     window.web3 = new Web3(window.web3.currentProvider)
  //   }
  //   else {
  //     window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
  //   }
  // }

  // async loadBlockchainData() {
  //   const web3 = window.web3
  //   // Load account
  //   console.log("triggered");
  //   const accounts = await web3.eth.getAccounts()
  //   this.setState({ account: accounts[0] })
  //   // Network ID
  //   const networkId = await web3.eth.net.getId()
  //   const networkData = Stellara.networks[networkId]
  //   if(networkData) {      
  //     const stellara = new web3.eth.Contract(Stellara.abi, networkData.address)
  //     this.setState({ stellara })
  //     const imagesCount = await stellara.methods.imageCount().call()
  //     this.setState({ imagesCount })
  //     // Load images
  //     for (let i = 1; i <= imagesCount; i++) {
  //       const image = await stellara.methods.images(i).call()
  //       this.setState({
  //         images: [...this.state.images, image]
  //       })
  //     }
  //     // Sort images. Show highest tipped images first
  //     this.setState({
  //       images: this.state.images.sort((a,b) => b.tipAmount - a.tipAmount )
  //     })
  //     this.setState({ loading: false})
  //   } else {
  //     window.alert('stellara contract not deployed to detected network.')
  //   }
  // }

  // captureFile = event => {

  //   event.preventDefault()
  //   const file = event.target.files[0]
  //   const reader = new window.FileReader()
  //   reader.readAsArrayBuffer(file)

  //   reader.onloadend = () => {
  //     this.setState({ buffer: Buffer(reader.result) })
  //     console.log('buffer', this.state.buffer)
  //   }
  // }

  // uploadImage = description => {
  //   console.log("Submitting file to ipfs...")

  //   //adding file to the IPFS
  //   ipfs.add(this.state.buffer, (error, result) => {
  //     console.log('Ipfs result', result)
  //     if(error) {
  //       console.error(error)
  //       return
  //     }

  //     this.setState({ loading: true })
  //     this.state.stellara.methods.uploadImage(result[0].hash, description).send({ from: this.state.account }).on('transactionHash', (hash) => {
  //       this.setState({ loading: false })
  //     })
  //   })
  // }

  // tipImageOwner(id, tipAmount) {
  //   this.setState({ loading: true })
  //   this.state.stellara.methods.tipImageOwner(id).send({ from: this.state.account, value: tipAmount }).on('transactionHash', (hash) => {
  //     this.setState({ loading: false })
  //   }).catch(e => {
  //       console.log(e);
  //       this.loadBlockchainData();
  //   });
  // }

  constructor(props) {
    super(props)
    // this.state = {
    //   account: '',
    //   stellara: null,
    //   images: [],
    //   loading: true
    // }

    // this.uploadImage = this.uploadImage.bind(this)
    // this.tipImageOwner = this.tipImageOwner.bind(this)
    // this.captureFile = this.captureFile.bind(this)
  }

  render() {
    return (
      <Router>
        <Navbar 
        // account={this.state.account} 
        />
        <Routes>
          <Route path="/" exact element={
            // this.state.loading
            // ? <div id="loader" className="text-center mt-5"><p>Loading...</p></div>
            // : 
            <Main
                // images={this.state.images}
                // captureFile={this.captureFile}
                // uploadImage={this.uploadImage}
                // tipImageOwner={this.tipImageOwner}
              />
          } 
          />
          <Route path="/about" element={
            <About />
          } />
          <Route path="/contact" element={
            <Contact />
          } />
        </Routes>
      </Router>
    );
  }
}

export default App;