import React from 'react'
import {
  motion,
  useScroll,
  useTransform
} from "framer-motion/dist/framer-motion";

function useParallax(value, distance) {
  return useTransform(value, [0, 1], [distance, -distance]);
}

export default function Rocket({ className }) {

  const { scrollYProgress } = useScroll();
  const y = useParallax(scrollYProgress, 400);

  return (
    <motion.div style={{ y }}>
    <svg className={className} width='1440' height='2065' viewBox="0 0 1440 2065" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M732 1978.63C704.16 1959.92 655.56 1921.95 619.68 1857.67C611.027 1842.17 530.867 1692.8 605.28 1543.75C639.467 1475.24 692.213 1435.05 726.227 1414.15C754.107 1429.99 813.947 1469.08 852.96 1543.75C923.96 1679.65 864.467 1815.75 855.84 1834.63C819.56 1913.99 761.36 1958.97 732 1978.63Z" fill="#FCC000"/>
      <path d="M731.56 1888.89C712.573 1874.35 679.427 1844.83 654.947 1794.87C649.053 1782.83 594.373 1666.72 645.133 1550.85C668.453 1497.61 704.427 1466.37 727.627 1450.12C746.64 1462.44 787.453 1492.81 814.067 1550.85C862.493 1656.49 821.907 1762.28 816.027 1776.96C791.28 1838.65 751.587 1873.61 731.56 1888.89Z" fill="#F9CE62"/>
      <path d="M731.253 1741.55C718.64 1731.89 696.627 1712.28 680.36 1679.11C676.453 1671.11 640.133 1593.99 673.84 1517.03C689.333 1481.67 713.226 1460.91 728.64 1450.12C741.266 1458.29 768.373 1478.48 786.04 1517.03C818.213 1587.2 791.253 1657.47 787.346 1667.2C770.92 1708.17 744.547 1731.4 731.253 1741.55Z" fill="#EA6200"/>
      <path d="M842.68 1496.75H618.573L667.027 1272.64H794.24L842.68 1496.75Z" fill="#3B4647"/>
      <path d="M906.027 711.28C1007.49 872.253 1108.96 1033.22 1210.43 1194.19C1211.49 1237.39 1212.57 1280.59 1213.65 1323.79C1126.21 1326.67 1038.77 1329.55 951.333 1332.43C917.493 1123.38 883.653 914.333 849.8 705.28C868.546 707.28 887.28 709.28 906.027 711.28Z" fill="#3B4647"/>
      <mask id="mask0_501_10" styles="mask-type:luminance" maskUnits="userSpaceOnUse" x="849" y="705" width="365" height="628">
      <path d="M906.027 711.28C1007.49 872.253 1108.96 1033.22 1210.43 1194.2C1211.49 1237.39 1212.57 1280.59 1213.65 1323.79C1126.21 1326.67 1038.77 1329.55 951.346 1332.43C917.493 1123.38 883.653 914.333 849.8 705.28C868.546 707.28 887.28 709.28 906.027 711.28Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_501_10)">
      <path d="M1251.09 1369.87H228.693V1272.64H1251.09V1369.87Z" fill="#EA6200"/>
      </g>
      <path d="M553.867 718.28C452.44 877.813 351.013 1037.34 249.573 1196.86C248.507 1240.05 247.427 1283.25 246.347 1326.45C333.787 1329.33 421.227 1332.21 508.667 1335.09C523.733 1129.49 538.8 923.88 553.867 718.28Z" fill="#3B4647"/>
      <mask id="mask1_501_10" styles="mask-type:luminance" maskUnits="userSpaceOnUse" x="246" y="718" width="308" height="618">
      <path d="M553.867 718.28C452.44 877.813 351.013 1037.34 249.573 1196.86C248.507 1240.05 247.427 1283.25 246.347 1326.45C333.787 1329.33 421.227 1332.21 508.667 1335.09C523.733 1129.49 538.8 923.88 553.867 718.28Z" fill="white"/>
      </mask>
      <g mask="url(#mask1_501_10)">
      <path d="M1251.09 1369.87H228.693V1272.64H1251.09V1369.87Z" fill="#EA6200"/>
      </g>
      <path d="M951.347 1332.43H509.067L580.987 346.6C587.2 261.4 653.8 197.373 730.2 197.373C806.6 197.373 873.213 261.4 879.427 346.6L951.347 1332.43Z" fill="#EFEFEF"/>
      <mask id="mask2_501_10" styles="mask-type:luminance" maskUnits="userSpaceOnUse" x="509" y="197" width="443" height="1136">
      <path d="M951.347 1332.43H509.067L580.987 346.6C587.2 261.4 653.8 197.373 730.2 197.373C806.6 197.373 873.213 261.4 879.427 346.6L951.347 1332.43Z" fill="white"/>
      </mask>
      <g mask="url(#mask2_501_10)">
      <path d="M1251.09 1369.87H228.693V1272.64H1251.09V1369.87Z" fill="#3B4647"/>
      <path d="M904.573 502.907C880.76 518.733 816.973 556.587 727.04 556.947C635.573 557.307 570.64 518.667 546.933 502.907C546.933 432.587 546.933 362.253 546.933 291.933H904.573V502.907Z" fill="#EFEFEF"/>
      <path d="M968.44 467.173C936.08 483 849.333 520.854 727.053 521.2C602.68 521.574 514.4 482.934 482.16 467.173C482.16 396.84 482.16 326.52 482.16 256.2H968.44V467.173Z" fill="#3B4647"/>
      <path d="M968.44 426.08C936.08 441.893 849.333 479.747 727.053 480.107C602.68 480.48 514.4 441.84 482.16 426.08C482.16 355.747 482.16 285.427 482.16 215.093H968.44V426.08Z" fill="#EFEFEF"/>
      <path d="M968.44 390.347C936.08 406.16 849.333 444.013 727.053 444.373C602.68 444.733 514.4 406.107 482.16 390.347C482.16 320.013 482.16 249.693 482.16 179.36H968.44V390.347Z" fill="#3B4647"/>
      <path d="M722.827 841C773.787 843.36 814.84 799.16 817.387 755.893C819.933 712.907 784.84 664.907 733.36 662.667C682.867 660.48 643.253 703.467 639.347 744.813C635.2 788.813 670.773 838.587 722.827 841Z" fill="#EA6200"/>
      <path d="M724.173 819.04C762.387 820.813 793.173 787.667 795.093 755.213C797 722.973 770.68 686.973 732.067 685.293C694.2 683.653 664.493 715.893 661.56 746.893C658.44 779.893 685.133 817.227 724.173 819.04Z" fill="#3B4647"/>
      <path d="M667.12 734.773C664.347 761.213 681.613 786.013 708.88 795.387C733.747 803.947 762.627 798.2 781.627 780.84C780.067 784.187 772.467 799.373 752.653 807.213C731.053 815.76 711.387 809.04 708.027 807.84C701.867 805.16 685.587 797.187 674.893 780C662.267 759.72 665.893 740.24 667.12 734.773Z" fill="#596768"/>
      <path d="M554.653 971.823C549.613 943.373 544.96 908.493 542.92 868.427L557.867 663.533C568.827 608.813 583.12 566.48 593.24 537C594.08 534.547 594.947 532.067 595.84 529.547C607.68 534.747 621.16 539.827 636.133 544.16C633.16 555.84 630.24 567.613 627.347 579.48C593.253 719.707 570.147 851.387 554.653 971.823Z" fill="#E9EEEF"/>
      <path d="M636.133 544.16C621.16 539.827 607.68 534.747 595.84 529.547C598.227 522.773 600.787 515.733 603.533 508.453C616.107 511.133 629.453 513.533 643.52 515.52C641.04 525 638.573 534.547 636.133 544.16Z" fill="#E9EEEF"/>
      <path d="M643.52 515.52C629.453 513.533 616.107 511.133 603.533 508.453C608.08 496.387 613.133 483.68 618.773 470.387C630.04 472.48 641.853 474.32 654.187 475.813C650.587 488.92 647.04 502.147 643.52 515.52Z" fill="#7D898A"/>
      <path d="M654.187 475.813C641.853 474.32 630.04 472.48 618.773 470.387C623.32 459.68 628.227 448.6 633.56 437.2C643.307 438.747 653.413 440.08 663.893 441.187C660.627 452.613 657.387 464.173 654.187 475.813Z" fill="#E9EEEF"/>
      <path d="M663.893 441.187C653.413 440.08 643.307 438.747 633.56 437.2C651.787 398.12 674.787 355.293 703.88 310.587C690.187 352.467 676.8 396.013 663.893 441.187Z" fill="#7D898A"/>
      </g>
      <path d="M513.347 1605.29C504.787 1599.53 489.84 1587.87 478.813 1568.09C476.147 1563.33 451.507 1517.4 474.387 1471.57C484.893 1450.51 501.12 1438.15 511.573 1431.72C520.147 1436.6 538.547 1448.61 550.547 1471.57C572.373 1513.36 554.08 1555.21 551.427 1561.01C540.267 1585.41 522.373 1599.24 513.347 1605.29Z" fill="#FCC000"/>
      <path d="M513.213 1577.69C507.373 1573.23 497.187 1564.15 489.653 1548.79C487.84 1545.08 471.026 1509.39 486.64 1473.76C493.813 1457.39 504.866 1447.77 512 1442.79C517.853 1446.57 530.4 1455.91 538.587 1473.76C553.467 1506.24 541 1538.77 539.187 1543.28C531.573 1562.25 519.373 1573 513.213 1577.69Z" fill="#F9CE62"/>
      <path d="M513.12 1532.39C509.24 1529.43 502.467 1523.4 497.467 1513.19C496.267 1510.73 485.107 1487.01 495.467 1463.36C500.227 1452.48 507.573 1446.11 512.32 1442.79C516.2 1445.29 524.533 1451.51 529.973 1463.36C539.853 1484.93 531.573 1506.53 530.373 1509.53C525.32 1522.13 517.213 1529.27 513.12 1532.39Z" fill="#EA6200"/>
      <path d="M547.387 1457.12H478.467L493.373 1388.21H532.48L547.387 1457.12Z" fill="#3B4647"/>
      <path d="M573.893 1405.71H448.853V1369.87H573.893V1405.71Z" fill="#3B4647"/>
      <path d="M585.813 1384.69H436.053V1041.55C436.053 1000.19 469.573 966.667 510.933 966.667C552.28 966.667 585.813 1000.19 585.813 1041.55V1384.69Z" fill="#ADAAA8"/>
      <path d="M945.84 1605.29C937.28 1599.53 922.333 1587.87 911.293 1568.09C908.64 1563.33 883.986 1517.4 906.866 1471.57C917.386 1450.51 933.6 1438.15 944.066 1431.72C952.64 1436.6 971.04 1448.61 983.026 1471.57C1004.87 1513.36 986.56 1555.21 983.92 1561.01C972.76 1585.41 954.867 1599.24 945.84 1605.29Z" fill="#FCC000"/>
      <path d="M945.707 1577.69C939.867 1573.23 929.666 1564.15 922.146 1548.79C920.333 1545.08 903.52 1509.39 919.12 1473.76C926.293 1457.39 937.36 1447.77 944.493 1442.79C950.346 1446.57 962.893 1455.91 971.066 1473.76C985.96 1506.24 973.48 1538.77 971.68 1543.28C964.066 1562.25 951.867 1573 945.707 1577.69Z" fill="#F9CE62"/>
      <path d="M945.613 1532.39C941.733 1529.43 934.96 1523.4 929.96 1513.19C928.76 1510.73 917.587 1487.01 927.96 1463.36C932.72 1452.48 940.067 1446.11 944.8 1442.79C948.693 1445.29 957.027 1451.51 962.453 1463.36C972.347 1484.93 964.053 1506.53 962.853 1509.53C957.8 1522.13 949.693 1529.27 945.613 1532.39Z" fill="#EA6200"/>
      <path d="M979.867 1457.12H910.96L925.853 1388.21H964.973L979.867 1457.12Z" fill="#3B4647"/>
      <path d="M1006.39 1405.71H881.333V1369.87H1006.39V1405.71Z" fill="#3B4647"/>
      <path d="M1018.29 1384.69H868.533V1041.55C868.533 1000.19 902.066 966.667 943.413 966.667C984.773 966.667 1018.29 1000.19 1018.29 1041.55V1384.69Z" fill="#ADAAA8"/>
    </svg>
    </motion.div>
  )
}


