import * as React from 'react';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Spaceship from '../assets/spaceship-svg';
import Copyright from '../components/Copyright';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Button, CssBaseline, TextField, Grid, Box, Container } from '@mui/material';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const defaultTheme = createTheme();
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SignUp() {
  const form = useRef();
  const successOpen = useRef();
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const HandleSubmit = async(e) => {
    e.preventDefault();
  

    const serviceID = process.env.REACT_APP_SERVICE_ID;
    const templateID = process.env.REACT_APP_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_PUBLIC_KEY;

    emailjs.sendForm(serviceID, templateID, form.current, publicKey)
      .then(function(response) {
        //TODO ADD SUCCESS MESSAGE
        setOpen(true);
        console.log('SUCCESS!', response.status, response.text);
      }, function(error) {
        setOpen(true);
        //TODO ADD SUCCESS MESSAGE
         console.log('FAILED...', error);
      });

  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container className="contact-wrapper" component="main" maxWidth="xs">
        <CssBaseline />
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
           Your message was sent!
          </Alert>
        </Snackbar>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>

          <Spaceship className="spaceship" />

          {/* <Typography component="h1" variant="h5">
            Contact Us
          </Typography> */}
          
          <Box component="form" ref={form} noValidate onSubmit={HandleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Email Address"
                  name="from_name"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="company"
                  label="Company"
                  autoComplete="company"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Project Description"
                  fullWidth
                  multiline
                  name="message"
                  rows={4}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
        <div className="copyright">
          <Copyright sx={{ mt: 5 }} />
        </div>
      </Container>
    </ThemeProvider>
  );
}