import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Typography, } from '@mui/material';

class Copyright extends Component {

  render() {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://stellaraconsulting.com/">
          Stellara Consulting
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
}

export default Copyright;