import React, { Component } from 'react';
import ShowCard from '../components/Cards';
import austin from '../assets/austin-mayer.jpeg'
import danielle from '../assets/danielle-mckellar.jpeg'
import tyler from '../assets/tyler-matthews.png'

const cards = [
  {
    image: danielle,
    alt: 'web',
    title: 'Danielle McKellar',
    sub: 'Danielle McKellar is a highly skilled and accomplished professional with a strong background in the fashion industry and graphic design. As the Owner of COUTONIC, she founded a boutique and slow fashion brand dedicated to ethical practices, eco-consciousness, and social responsibility. Danielle successfully expanded the brand\'s online presence, curated sustainable fashion items, and pioneered the development of in-house sustainable products. With her expertise in UI/UX design, she contributed significantly to multiple companies online presence and international expansion. Danielle is proficient in a wide range of design software and holds a Bachelor\'s degree in Digital Arts and Design, and she received the Course Director Award in 3D Animation.'
  },
  {
    image: austin,
    alt: 'ui/ux',
    title: 'Austin Mayer',
    sub: 'Austin Mayer is a seasoned Senior Principal Software Engineer with extensive experience in leading and managing development teams. He successfully oversaw the complete lifecycle of custom web and mobile applications, demonstrating strong leadership in architecture, coding, testing, and support. Austin\'s expertise includes utilizing technologies such as Ionic, React, Angular, NodeJS, Java, and various CI/CD tools to create seamless mobile experiences. With a background in full stack development and a Bachelor\'s degree in Web Design & Development, he excels in bridging the gap between UI/UX design and backend API engineering, ensuring the successful development and deployment of innovative digital solutions.'
  },
  {
    image: tyler,
    alt: 'tyler matthews',
    title: 'Tyler Matthews',
    sub: 'Tyler Matthews is an experienced Web Application Developer based in Orlando, FL, with a strong background in software engineering. He has a proven track record in designing and implementing innovative features for both wireless and wireline applications, enhancing user productivity and efficiency. Tyler is skilled in various technologies, including PHP, Laravel, JavaScript, and databases like MariaDB and MongoDB, and possesses expertise in application architecture, automated testing, API development, and project management.'
  }
];

class About extends Component {

  render() {
    return (
      <div className="container-fluid mt-5">
        <div className="row">
          <main role="main" className="about-wrapper col-lg-12 ml-auto mr-auto" style={{ maxWidth: '500px' }}>
            <div className="content mr-auto ml-auto">

              <h1 className="agd">About Our Devs</h1>
              <h5 className="agd-sub">
                Together, our diverse talents converge to create holistic solutions. With a blend of fashion acumen, software engineering 
                excellence, and innovative app development, we offer a comprehensive approach to projects, ensuring unmatched quality and success.
              </h5>
              {/* <h5 className="agd-sub">
                As a dynamic and multidisciplinary team, we bring a wealth of expertise to any project. Danielle, 
                a visionary fashion entrepreneur, leads our creative endeavors with a focus on sustainability, ethical 
                practices, and development while excelling in UI/UX design. Austin, a Senior Principal Software Engineer, 
                drives technological innovation, specializing in web and mobile app development. Tyler, an experienced Web Application 
                Developer, brings his skills in custom branded web apps and user-friendly experiences, reinforcing brand identities.
              </h5> */}
              
              <div className='cards'>
                {cards.map((card) => (
                  <div className='card' sm>
                    <ShowCard {...card} />
                  </div>
                ))}
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default About;
