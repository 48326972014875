import React from 'react'
import {
  motion,
  useViewportScroll,
} from "framer-motion/dist/framer-motion";

export default function Tardis({ className }) {

  const { scrollY } = useViewportScroll();
  console.log(scrollY)

  return (
    <motion.div className="tardis-wrapper" style={{rotateY: scrollY}}>
      <svg className={className} width="708" height="844" viewBox="0 0 708 844" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M555.3 705H354H152.7L0.100098 844H354H707.9L555.3 705Z" fill="#919396"/>
        <path d="M372.5 25.6H335.4V62.3H372.5V25.6Z" fill="#FFFCDF"/>
        <path d="M526.8 90.8H181.2V668.9H526.8V90.8Z" fill="#4180B0"/>
        <path d="M547.6 117.8H160.4V160.8H547.6V117.8Z" fill="#18466D"/>
        <path d="M526.8 117.8H181.2V668.9H526.8V117.8Z" fill="#215785"/>
        <path d="M485 160.8H223V668.9H485V160.8Z" fill="#18466D"/>
        <path d="M361 160.8H347.1V668.9H361V160.8Z" fill="#4180B0"/>
        <path d="M498.9 160.8H485V668.9H498.9V160.8Z" fill="#4180B0"/>
        <path d="M223 160.8H209.1V668.9H223V160.8Z" fill="#4180B0"/>
        <path d="M507.9 117.8H200.1V160.8H507.9V117.8Z" fill="#0B3558"/>
        <path d="M514.6 76H193.5V90.8H514.6V76Z" fill="#215785"/>
        <path d="M539.3 669H168.6V683.8H539.3V669Z" fill="#215785"/>
        <path d="M555.2 683.7H152.7V705H555.2V683.7Z" fill="#4180B0"/>
        <path d="M327.3 187.3H242.7V272.6H327.3V187.3Z" fill="#FFFCDF"/>
        <path d="M327.3 310.6H242.7V395.9H327.3V310.6Z" fill="#215785"/>
        <path d="M327.3 435.3H242.7V519.2H327.3V435.3Z" fill="#215785"/>
        <path d="M327.3 557.2H242.7V642.5H327.3V557.2Z" fill="#215785"/>
        <path d="M465.3 187.3H380.7V272.6H465.3V187.3Z" fill="#FFFCDF"/>
        <path d="M465.3 310.6H380.7V395.9H465.3V310.6Z" fill="#215785"/>
        <path d="M465.3 435.3H380.7V519.2H465.3V435.3Z" fill="#215785"/>
        <path d="M465.3 557.2H380.7V642.5H465.3V557.2Z" fill="#215785"/>
        <path d="M530.3 672.5H177.7V87.3H530.3V672.5ZM184.7 665.5H523.3V94.3H184.7V665.5Z" fill="#0B3558"/>
        <path d="M551.1 164.3H523.3V114.3H551.1V164.3ZM530.3 157.3H544.1V121.3H530.3V157.3Z" fill="#0B3558"/>
        <path d="M184.7 164.3H156.9V114.3H184.7V164.3ZM163.9 157.3H177.7V121.3H163.9V157.3Z" fill="#0B3558"/>
        <path d="M530.3 672.5H177.7V114.3H530.3V672.5ZM184.7 665.5H523.3V121.3H184.7V665.5Z" fill="#0B3558"/>
        <path d="M488.5 672.5H219.5V157.3H488.5V672.5ZM226.5 665.5H481.5V164.3H226.5V665.5Z" fill="#0B3558"/>
        <path d="M364.4 672.5H343.5V157.3H364.4V672.5ZM350.6 665.5H357.5V164.3H350.6V665.5Z" fill="#0B3558"/>
        <path d="M502.4 672.5H481.5V157.3H502.4V672.5ZM488.5 665.5H495.4V164.3H488.5V665.5Z" fill="#0B3558"/>
        <path d="M226.5 672.5H205.6V157.3H226.5V672.5ZM212.6 665.5H219.5V164.3H212.6V665.5Z" fill="#0B3558"/>
        <path d="M511.4 164.3H196.6V114.3H511.4V164.3ZM203.6 157.3H504.4V121.3H203.6V157.3Z" fill="#0B3558"/>
        <path d="M518 94.3H190V72.5H518V94.3ZM197 87.3H511V79.5H197V87.3Z" fill="#0B3558"/>
        <path d="M518 94.3H190V72.5H518V94.3ZM197 87.3H511V79.5H197V87.3Z" fill="#0B3558"/>
        <path d="M383 62.4H325.1V76H383V62.4Z" fill="#0B3558"/>
        <path d="M383 62.4H325.1V76H383V62.4Z" fill="#0B3558"/>
        <path d="M383 62.4H325.1V76H383V62.4Z" fill="#0B3558"/>
        <path d="M385.4 78.5H322.5V59.9H385.4V78.5ZM327.6 73.5H380.5V64.9H327.6V73.5Z" fill="#0B3558"/>
        <path d="M360.3 3H347.7C338.7 3 331.4 10.3 331.4 19.3V25.6H376.7V19.3C376.6 10.3 369.3 3 360.3 3Z" fill="#0B3558"/>
        <path d="M379.1 28.1H328.8V19.3C328.8 8.9 337.2 0.5 347.6 0.5H360.2C370.6 0.5 379 8.9 379 19.3V28.1H379.1ZM333.9 23.1H374.2V19.3C374.2 11.7 368 5.5 360.4 5.5H347.8C340.2 5.5 334 11.7 334 19.3V23.1H333.9Z" fill="#0B3558"/>
        <path d="M542.9 687.2H165.1V665.4H542.8V687.2H542.9ZM172.1 680.2H535.8V672.4H172.1V680.2Z" fill="#0B3558"/>
        <path d="M558.8 708.5H149.2V680.2H558.7V708.5H558.8ZM156.2 701.5H551.7V687.2H156.2V701.5Z" fill="#0B3558"/>
        <path d="M330.8 276.1H239.2V183.8H330.8V276.1ZM246.2 269.1H323.8V190.8H246.2V269.1Z" fill="#0B3558"/>
        <path d="M330.8 399.4H239.2V307.1H330.8V399.4ZM246.2 392.4H323.8V314.1H246.2V392.4Z" fill="#0B3558"/>
        <path d="M330.8 522.7H239.2V431.8H330.8V522.7ZM246.2 515.7H323.8V438.8H246.2V515.7Z" fill="#0B3558"/>
        <path d="M330.8 646H239.2V553.7H330.8V646ZM246.2 639H323.8V560.7H246.2V639Z" fill="#0B3558"/>
        <path d="M468.8 276.1H377.2V183.8H468.8V276.1ZM384.2 269.1H461.8V190.8H384.2V269.1Z" fill="#0B3558"/>
        <path d="M468.8 399.4H377.2V307.1H468.8V399.4ZM384.2 392.4H461.8V314.1H384.2V392.4Z" fill="#0B3558"/>
        <path d="M468.8 522.7H377.2V431.8H468.8V522.7ZM384.2 515.7H461.8V438.8H384.2V515.7Z" fill="#0B3558"/>
        <path d="M468.8 646H377.2V553.7H468.8V646ZM384.2 639H461.8V560.7H384.2V639Z" fill="#0B3558"/>
        <path d="M387 421.3C390.535 421.3 393.4 418.435 393.4 414.9C393.4 411.365 390.535 408.5 387 408.5C383.465 408.5 380.6 411.365 380.6 414.9C380.6 418.435 383.465 421.3 387 421.3Z" fill="#4180B0"/>
        <path d="M387 424.7C381.6 424.7 377.1 420.3 377.1 414.8C377.1 409.3 381.5 404.9 387 404.9C392.4 404.9 396.9 409.3 396.9 414.8C396.9 420.3 392.5 424.7 387 424.7ZM387 412C385.4 412 384.1 413.3 384.1 414.9C384.1 416.5 385.4 417.8 387 417.8C388.6 417.8 389.9 416.5 389.9 414.9C389.9 413.3 388.6 412 387 412Z" fill="#0B3558"/>
        <path d="M321 421.3C324.535 421.3 327.4 418.435 327.4 414.9C327.4 411.365 324.535 408.5 321 408.5C317.465 408.5 314.6 411.365 314.6 414.9C314.6 418.435 317.465 421.3 321 421.3Z" fill="#4180B0"/>
        <path d="M321 424.7C315.6 424.7 311.1 420.3 311.1 414.8C311.1 409.3 315.5 404.9 321 404.9C326.4 404.9 330.9 409.3 330.9 414.8C330.9 420.3 326.4 424.7 321 424.7ZM321 412C319.4 412 318.1 413.3 318.1 414.9C318.1 416.5 319.4 417.8 321 417.8C322.6 417.8 323.9 416.5 323.9 414.9C323.9 413.3 322.5 412 321 412Z" fill="#0B3558"/>
        <path d="M288.5 187.3H281.5V272.6H288.5V187.3Z" fill="#0B3558"/>
        <path d="M327.3 226.4H242.7V233.4H327.3V226.4Z" fill="#0B3558"/>
        <path d="M426.5 187.3H419.5V272.6H426.5V187.3Z" fill="#0B3558"/>
        <path d="M465.3 226.4H380.7V233.4H465.3V226.4Z" fill="#0B3558"/>
        <path d="M252.6 140.4C251.6 141.3 250.1 141.7 248.2 141.7H244.5V148.1H240.8V130.4H248.4C250.2 130.4 251.5 130.9 252.6 131.8C253.6 132.7 254.2 134.1 254.2 136C254.1 138.1 253.6 139.6 252.6 140.4ZM249.7 134.1C249.2 133.7 248.6 133.5 247.7 133.5H244.4V138.7H247.7C248.5 138.7 249.2 138.5 249.7 138.1C250.2 137.7 250.4 137 250.4 136.1C250.4 135.1 250.2 134.5 249.7 134.1Z" fill="white"/>
        <path d="M270.7 146.5C269.4 147.9 267.4 148.6 264.9 148.6C262.4 148.6 260.4 147.9 259.1 146.5C257.3 144.8 256.4 142.4 256.4 139.2C256.4 136 257.3 133.5 259.1 131.9C260.4 130.5 262.4 129.8 264.9 129.8C267.4 129.8 269.4 130.5 270.7 131.9C272.5 133.5 273.4 136 273.4 139.2C273.4 142.4 272.5 144.8 270.7 146.5ZM268.4 143.8C269.3 142.7 269.7 141.2 269.7 139.2C269.7 137.2 269.3 135.7 268.4 134.6C267.5 133.5 266.4 133 264.9 133C263.4 133 262.3 133.5 261.4 134.6C260.5 135.7 260.1 137.2 260.1 139.2C260.1 141.2 260.5 142.7 261.4 143.8C262.3 144.9 263.4 145.4 264.9 145.4C266.4 145.4 267.5 144.9 268.4 143.8Z" fill="white"/>
        <path d="M276.2 130.4H279.9V144.9H288.7V148.1H276.2V130.4Z" fill="white"/>
        <path d="M294.6 148.1H290.9V130.4H294.6V148.1Z" fill="white"/>
        <path d="M299.7 132.3C301.1 130.8 302.9 130.1 305.1 130.1C308 130.1 310.2 131.1 311.6 133C312.4 134.1 312.8 135.2 312.8 136.3H309.1C308.9 135.5 308.6 134.8 308.2 134.4C307.5 133.6 306.6 133.2 305.3 133.2C304 133.2 302.9 133.7 302.2 134.8C301.4 135.9 301.1 137.4 301.1 139.4C301.1 141.4 301.5 142.9 302.3 143.9C303.1 144.9 304.1 145.4 305.4 145.4C306.7 145.4 307.6 145 308.3 144.1C308.7 143.6 309 143 309.2 142H312.9C312.6 143.9 311.8 145.5 310.5 146.7C309.2 147.9 307.5 148.5 305.5 148.5C303 148.5 301 147.7 299.6 146.1C298.2 144.5 297.4 142.2 297.4 139.4C297.3 136.3 298.1 133.9 299.7 132.3Z" fill="white"/>
        <path d="M328.8 133.5H319.4V137.3H328V140.4H319.4V144.9H329.2V148.1H315.8V130.4H328.8V133.5Z" fill="white"/>
        <path d="M347.9 136.2C347.4 136.5 346.7 136.7 345.8 136.7H344V139.1H342.2V132.5H345.8C346.6 132.5 347.3 132.7 347.8 133C348.3 133.3 348.5 133.9 348.5 134.6C348.6 135.3 348.4 135.9 347.9 136.2ZM346.5 133.8C346.3 133.7 346 133.6 345.6 133.6H344V135.5H345.6C346 135.5 346.3 135.4 346.5 135.3C346.7 135.1 346.8 134.9 346.8 134.5C346.8 134.1 346.8 133.9 346.5 133.8Z" fill="white"/>
        <path d="M350 132.4H351.8V136.5C351.8 137 351.9 137.3 352 137.5C352.2 137.9 352.7 138.1 353.4 138.1C354.1 138.1 354.6 137.9 354.8 137.5C354.9 137.3 355 137 355 136.5V132.4H356.8V136.5C356.8 137.2 356.7 137.8 356.4 138.1C355.9 138.8 354.9 139.2 353.4 139.2C351.9 139.2 350.9 138.8 350.4 138.1C350.1 137.7 350 137.2 350 136.5V132.4Z" fill="white"/>
        <path d="M364.8 133.1C365.1 133.4 365.2 133.7 365.2 134.1C365.2 134.5 365.1 134.8 364.8 135.1C364.7 135.2 364.4 135.4 364.2 135.5C364.6 135.6 365 135.8 365.2 136.1C365.4 136.4 365.5 136.7 365.5 137.1C365.5 137.5 365.4 137.8 365.1 138.2C364.9 138.4 364.7 138.6 364.5 138.7C364.2 138.9 363.9 139 363.5 139C363.1 139.1 362.7 139.1 362.3 139.1H358.5V132.5H362.6C363.7 132.4 364.4 132.7 364.8 133.1ZM360.2 133.6V135.1H362.3C362.7 135.1 363 135 363.2 134.9C363.4 134.8 363.5 134.6 363.5 134.3C363.5 134 363.3 133.8 363 133.7C362.7 133.6 362.4 133.6 362 133.6H360.2ZM360.2 136.1V137.9H362.3C362.7 137.9 363 137.9 363.2 137.8C363.6 137.7 363.8 137.4 363.8 137C363.8 136.7 363.6 136.4 363.3 136.3C363.1 136.2 362.8 136.2 362.5 136.2H360.2V136.1Z" fill="white"/>
        <path d="M367 132.4H368.8V137.8H373V139H367V132.4Z" fill="white"/>
        <path d="M375.8 139H374V132.4H375.8V139Z" fill="white"/>
        <path d="M378.2 133.1C378.9 132.6 379.7 132.3 380.8 132.3C382.2 132.3 383.2 132.7 383.9 133.4C384.3 133.8 384.5 134.2 384.5 134.6H382.7C382.6 134.3 382.4 134 382.3 133.9C382 133.6 381.5 133.5 380.9 133.5C380.3 133.5 379.8 133.7 379.4 134.1C379 134.5 378.9 135.1 378.9 135.8C378.9 136.5 379.1 137.1 379.5 137.5C379.9 137.9 380.4 138.1 381 138.1C381.6 138.1 382.1 137.9 382.4 137.6C382.6 137.4 382.7 137.2 382.8 136.8H384.6C384.4 137.5 384.1 138.1 383.4 138.6C382.8 139.1 382 139.3 381 139.3C379.8 139.3 378.9 139 378.2 138.4C377.5 137.8 377.2 136.9 377.2 135.9C377.1 134.6 377.4 133.7 378.2 133.1Z" fill="white"/>
        <path d="M349.3 144.1C350 143.6 350.8 143.3 351.9 143.3C353.3 143.3 354.3 143.7 355 144.4C355.4 144.8 355.6 145.2 355.6 145.6H353.8C353.7 145.3 353.5 145 353.4 144.9C353.1 144.6 352.6 144.5 352 144.5C351.4 144.5 350.9 144.7 350.5 145.1C350.1 145.5 350 146.1 350 146.8C350 147.5 350.2 148.1 350.6 148.5C351 148.9 351.5 149.1 352.1 149.1C352.7 149.1 353.2 148.9 353.5 148.6C353.7 148.4 353.8 148.2 353.9 147.8H355.7C355.5 148.5 355.2 149.1 354.5 149.6C353.9 150.1 353.1 150.3 352.1 150.3C350.9 150.3 350 150 349.3 149.4C348.6 148.8 348.3 147.9 348.3 146.9C348.1 145.7 348.5 144.8 349.3 144.1Z" fill="white"/>
        <path d="M359.4 143.5H361.4L364.4 150.1H362.5L361.9 148.7H358.8L358.2 150.1H356.4L359.4 143.5ZM359.3 147.6H361.5L360.4 145L359.3 147.6Z" fill="white"/>
        <path d="M365.5 143.5H367.3V148.9H371.5V150.1H365.5V143.5Z" fill="white"/>
        <path d="M372.6 143.5H374.4V148.9H378.6V150.1H372.6V143.5Z" fill="white"/>
        <path d="M410.6 132.3C411.1 133.1 411.4 134 411.4 135C411.4 136.1 411.1 136.9 410.6 137.6C410.3 138 409.8 138.3 409.3 138.6C410.2 138.9 410.9 139.4 411.3 140.2C411.8 140.9 412 141.8 412 142.8C412 143.8 411.7 144.8 411.2 145.6C410.9 146.2 410.4 146.6 409.9 147C409.3 147.4 408.7 147.7 407.9 147.9C407.1 148.1 406.3 148.1 405.4 148.1H397.4V130.4H405.9C408.2 130.4 409.7 131.1 410.6 132.3ZM401 133.5V137.4H405.3C406.1 137.4 406.7 137.3 407.2 137C407.7 136.7 407.9 136.2 407.9 135.4C407.9 134.6 407.6 134 406.9 133.8C406.4 133.6 405.7 133.5 404.8 133.5H401ZM401 140.3V145H405.3C406.1 145 406.7 144.9 407.1 144.7C407.9 144.3 408.3 143.6 408.3 142.5C408.3 141.6 407.9 140.9 407.2 140.6C406.8 140.4 406.2 140.3 405.4 140.3H401Z" fill="white"/>
        <path d="M428.8 146.5C427.5 147.9 425.5 148.6 423 148.6C420.5 148.6 418.5 147.9 417.2 146.5C415.4 144.8 414.5 142.4 414.5 139.2C414.5 136 415.4 133.5 417.2 131.9C418.5 130.5 420.5 129.8 423 129.8C425.5 129.8 427.5 130.5 428.8 131.9C430.6 133.5 431.5 136 431.5 139.2C431.4 142.4 430.6 144.8 428.8 146.5ZM426.5 143.8C427.4 142.7 427.8 141.2 427.8 139.2C427.8 137.2 427.4 135.7 426.5 134.6C425.6 133.5 424.5 133 423 133C421.5 133 420.4 133.5 419.5 134.6C418.6 135.7 418.2 137.2 418.2 139.2C418.2 141.2 418.6 142.7 419.5 143.8C420.4 144.9 421.5 145.4 423 145.4C424.5 145.4 425.6 144.9 426.5 143.8Z" fill="white"/>
        <path d="M437.1 148.1H432.8L438.5 139.1L433.1 130.4H437.5L440.7 136.2L443.9 130.4H448.2L442.8 138.9L448.5 148.1H444L440.6 142.1L437.1 148.1Z" fill="white"/>
        <path d="M338.4 25.6H332.4V62.3H338.4V25.6Z" fill="#0B3558"/>
        <path d="M375.6 25.6H369.6V62.3H375.6V25.6Z" fill="#0B3558"/>
        <path d="M357 25.6H351V62.3H357V25.6Z" fill="#0B3558"/>

      </svg>
    </motion.div>
  )
}
